import { Routes } from '@angular/router';

import { AuthLayoutComponent } from '@auth/components/auth-layout/auth-layout.component';
import { LoginComponent } from '../components/login/login.component';
import { ResetPasswordRequestComponent } from '../components/reset-password-request/reset-password-request.component';
import { ChangePasswordComponent } from '../components/change-password/change-password.component';
import { ResetPasswordComponent } from '../components/reset-password/reset-password.component';
import { SignupComponent } from '../components/signup/signup.component';
import { ImanageLoginComponent } from '../components/imanage-login/imanage-login.component';
import { ImanageLayoutComponent } from '@auth/components/imanage-layout/imanage-layout.component';
import { AuthGuard, RedirectGuard } from '../guards';

import { ResetCodeResolver, SignupCodeResolver } from '../resolvers';
import { ImanageRedirectComponent } from '../components/imanage-redirect/imanage-redirect.component';
import { ExternalRedirectComponent } from '@shared/components/external-redirect/external-redirect.component';

export const authRoutes: Routes = [
    {
        path: '',
        component: AuthLayoutComponent,
        children: [
            {
                path: '',
                redirectTo: '/title/upload',
                pathMatch: 'full',
            },
            {
                path: 'login',
                component: LoginComponent,
                canActivate: [RedirectGuard],
            },
            {
                path: 'signup',
                component: SignupComponent,
                resolve: { actionCode: SignupCodeResolver },
            },
            {
                path: 'reset-password-req',
                component: ResetPasswordRequestComponent,
            },
            {
                path: 'reset-password',
                resolve: { actionCode: ResetCodeResolver },
                component: ResetPasswordComponent,
            },
            {
                path: 'change-password',
                component: ChangePasswordComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'successful',
                component: ImanageLoginComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'callback',
                component: ImanageRedirectComponent,
            },
        ],
    },
    {
        path: 'authentication',
        component: ImanageLayoutComponent,
        children: [
            {
                path: 'successful',
                component: ImanageLoginComponent,
                canActivate: [AuthGuard],
            },
        ],
    },
    {
        path: 'view',
        component: ExternalRedirectComponent,
        canActivate: [AuthGuard],
    },
];
