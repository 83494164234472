import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AuthService } from '@auth/services/auth.service';
import { RoutingService } from '@auth/services/routing.service';

import { LoggerService, UrlParamsService } from '@services';
import { OnboardingManageService } from 'app/onboarding/services';

import { environment } from '@env/environment';
import { User } from '@auth/types/user.type';
import { regexes } from '@constants';

@Component({
    selector: 'avl-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class LoginComponent implements OnInit {
    public readonly isPopupLoginFlow = environment.signInPopup;
    public loginForm: FormGroup;
    public loginError: string;
    public failedLogin = false;
    public isSso = false;
    public isPasswordVisible = false;

    private readonly ssoProviderKey = 'ssoProviderName';
    private ssoProviderName = '';

    constructor(
        private readonly authService: AuthService,
        private readonly routingService: RoutingService,
        private readonly route: ActivatedRoute,
        private readonly formBuilder: FormBuilder,
        private readonly log: LoggerService,
        private readonly onboarding: OnboardingManageService,
        private readonly urlParams: UrlParamsService,
    ) {
    }

    public get emailControl(): AbstractControl {
        return this.loginForm.get('email');
    }

    public get passwordControl(): AbstractControl {
        return this.loginForm.get('password');
    }

    public ngOnInit(): void {
        this.loginForm = this.formBuilder.group({
            email: [null, [Validators.required, Validators.pattern(regexes.email)]],
            password: [null, Validators.required],
        });

        this.route.queryParamMap.subscribe((params: ParamMap) => {
            this.onboarding.stop();
            this.ssoProviderName = params.get('sso') || localStorage.getItem(this.ssoProviderKey);

            if (this.ssoProviderName) {
                this.isSso = true;
                this.urlParams.addParams({ sso: this.ssoProviderName });
                localStorage.removeItem(this.ssoProviderKey);

                this.tryToRedirectOnSignInPage();
            }
        });
    }

    public getEmailErrorMessage(): string {
        return this.emailControl.hasError('required')
            ? 'An email is required'
            : this.emailControl.hasError('pattern')
                ? 'Not a valid email'
                : '';
    }

    public async login(): Promise<void> {
        if (this.loginForm.invalid) {
            return;
        }

        const user: User = this.loginForm.value;
        const isDevelopMode = environment.dev;
        const isBetaUser = user.email === 'beta';

        this.resetErrors();

        if (isDevelopMode && isBetaUser) {
            user.email = 'noreply@avail.ai';
        }

        this.authService.login(user.email, user.password)
            .then(() => this.routingService.navigateToHome(this.route.snapshot))
            .catch((error) => {
                this.log.info('Something went wrong:', error.message, error);
                const isUserNotFoundCode = error.code === 'auth/user-not-found';

                this.failedLogin = true;
                this.loginError = error.message;

                if (isUserNotFoundCode) {
                    this.loginError = 'We haven\'t been able to find an account linked to this email address. Please contact us at <a href="mailto:hello@avail.ai">hello@avail.ai</a> if you believe this is wrong.';
                }
            });
    }

    public resetErrors(): void {
        this.failedLogin = false;
        this.loginError = null;
    }

    public async openSsoModal(): Promise<void> {
        await this.authService.loginWithProvider(this.ssoProviderName)
            .then(() => localStorage.setItem(this.ssoProviderKey, this.ssoProviderName));
    }

    private tryToRedirectOnSignInPage(): void {
        if (!this.isPopupLoginFlow) {
            localStorage.setItem(this.ssoProviderKey, this.ssoProviderName);
            this.authService.loginWithProvider(this.ssoProviderName);
        }
    }
}
