import { Injectable } from '@angular/core';

import { FolderApi } from '../api';

import { FolderStore, MapSearchStore } from '../store';

import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { IDocument, ImanageParams } from '../types';
import { ProfileService } from '@services';
import { HttpResponse, HttpStatusCode } from '@angular/common/http';
import { FolderDetails, IResponseStatus } from '@core/types';

@Injectable()
export class FolderService {

    constructor(
        private readonly folderApi: FolderApi,
        private readonly folderStore: FolderStore,
        private readonly profileService: ProfileService,
        private readonly mapSearchStore: MapSearchStore,
    ) {
    }

    public createFolder(): Observable<string> {
        return this.folderApi.createFolder()
            .pipe(
                tap((folderId) => this.folderStore.update({ id: folderId })),
                tap(() => this.mapSearchStore.reset()),
            );
    }

    public getCurrentFolder(id: string): Observable<IResponseStatus<IDocument>> {
        return this.folderApi.getFolder(id)
            .pipe(
                tap((response) => {
                    this.folderStore.update({ id: response.id });
                }),
            );
    }

    public createProject(folderId: string, project: FolderDetails): Observable<HttpResponse<FolderDetails>> {
        return this.folderApi.createProject(folderId, project)
            .pipe(
                switchMap(() => this.updateProjectStatus(folderId)),
            );
    }

    public updateProjectStatus(folderId: string): Observable<HttpResponse<FolderDetails>> {
        return this.getProjectStatus(folderId)
            .pipe(
                tap((response) => {
                    const { matterNumber, projectName } = response.body;
                    this.folderStore.update((state) => ({ ...state, matterNumber, projectName }));
                }),
            );
    }

    public projectAlreadyCreated(folderId: string): Observable<boolean> {
        if (!this.profileService.isHmlrEnabled$.getValue()) {
            return of(true);
        }

        return this.getProjectStatus(folderId)
            .pipe(
                catchError((error) => error.status === HttpStatusCode.Unauthorized ? throwError(error) : of(error)),
                map((response) => response.status === HttpStatusCode.Ok),
            );
    }

    public resetFolderStore(): void {
        this.folderStore.reset();
    }


    // Login to Imanage
    public logInToImanage(data: any, agentUrl: string | undefined = '', agentSecret: string | undefined = ''): Observable<any> {
        return this.folderApi.logInToImanage(data, agentUrl, agentSecret);
    }

    public checkIfAuthorised(): Observable<any> {
        return this.folderApi.checkIfAuthorised();
    }

    public getDialogTokenCall(agentUrl: string, agentSecret: string, authorization: string): Observable<any> {
        return this.folderApi.getDialogTokenCall(agentUrl, agentSecret, authorization);
    }

    public downloadFiles(folderId: string, confData: ImanageParams, data: any): Observable<any> {
        return this.folderApi.downloadFiles(folderId, confData, data);
    }

    public isDownloadFinished(confData: ImanageParams, taskId: string): Observable<any> {
        return this.folderApi.isDownloadFinished(confData, taskId);
    }

    public downloadFilesToUi(confData: ImanageParams, contentLocation: string): Observable<any> {
        return this.folderApi.downloadFilesToUi(confData, contentLocation);
    }

    public sendThemToAvail(folderId: string, formData: any): Observable<any> {
        return this.folderApi.sendThemToAvail(folderId, formData);
    }

    public sendImanageDataToAvail(folderId: string, data: any): Observable<any> {
        return this.folderApi.sendImanageDataToAvail(folderId, data);
    }

    public checkImanageConfiguration(): Observable<any> {
        return this.folderApi.checkImanageConfiguration();
    }

    private getProjectStatus(folderId: string): Observable<HttpResponse<FolderDetails>> {
        return this.folderApi.getProjectStatus(folderId);
    }
}
